<template>
  <div id="app">
    <v-card v-if="preFiltroVisible"  elevation="12">
      <v-form autocomplete="off" @submit.prevent="preFilterClick">
        <v-card-title>
          <span class="headline">{{config.title}}</span>
        </v-card-title>
        <v-card-text>
          <ValidationObserver ref="observer">
            <slot name="pre-filter"></slot>
          </ValidationObserver>
        </v-card-text>
        <v-row>
        <v-col cols="12" sm="2">
          <v-btn v-if="reload" color="primary" @click="preFilterClick()">
            Atualizar lista
          </v-btn>
          <v-btn v-else type="submit" color="primary" @click="preFilterClick()">
            <v-icon small>fa-search</v-icon>Pesquisar
          </v-btn>
        </v-col> 
          <slot name="pre-filter-buttons"></slot>
        </v-row>
      </v-form>
    </v-card>
    <v-alert :value="true" color="error" type="warning" v-if="searchError">{{searchError}}</v-alert>
    <div v-show="!searchError">
      <br />
      <v-alert
        v-show="noResult"
        :value="true"
        color="warning"
        type="warning"
      >Sem informações para exibir!</v-alert>
    </div>

    <!--- MODAL PARA INSERIR ---->
    <v-toolbar flat color="white">
      <v-text-field
        v-show="(!preFiltroVisible) && (!devexpress)"
        v-model="search"
        autofocus
        append-icon="fa-search"
        label="Pesquise por qualquer coluna"
        single-line
        hide-details
      ></v-text-field>

      <v-divider v-if="(!preFiltroVisible) && (!devexpress)" class="mx-4" inset vertical></v-divider>
      <v-dialog v-model="dialog" max-width="1000px" scrollable @click:outside="closeModal(true)" :persistent="modalPersistent">
        <template v-slot:activator="{ on }">

          <slot name="crud-buttons"></slot>

          <v-btn v-if="crud && canCreate" color="primary" v-on="on" @click.native="novo">
            <v-icon>fa-plus-square</v-icon>Novo
          </v-btn>
          <div v-on="on" v-bind:class="{'hasReload':reload}">
            <slot name="add" ref="add" v-on="on"></slot>
          </div>

          <p></p>
        </template>
        <v-card>
          <v-alert :value="erroSaving" color="error" type="error">{{erroSaving.message}}</v-alert>
          <v-card-title>
            <span class="headline">{{formTitle}} {{config.title}}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <ValidationObserver ref="observer" isFromTable="true">
                  <slot name="modal" ref="modal"></slot>
                </ValidationObserver>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" @click.native="save">
              <slot name="modal-ok" ref="modal-ok">
                <v-icon>fa-book</v-icon>Salvar
              </slot>
            </v-btn>
            <v-btn color="primary" @click.native="closeModal">
              <slot name="modal-exit" ref="modal-exit">
                <v-icon>fa-times</v-icon>Cancelar
              </slot>
            </v-btn>



          </v-card-actions>
        </v-card>
      </v-dialog>      
      <v-btn
        v-for="c in config.customButtons"
        class="customButtons"
        v-bind:key="c.key" 
        color="primary"
        @click="preFilterClick(c)">
        {{c.label}}
      </v-btn>
    </v-toolbar>
    <!------ MODAL PRA EXCLUIR ------->
    <v-dialog v-model="confirmDelete" max-width="1000px" scrollable @click:outside="closeModal">
      <v-card>
        <v-alert :value="erroSaving" color="error" type="error">{{erroSaving.message}}</v-alert>
        <v-card-title>
          <span class="headline">Confirma a exclusão?</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap class="deleteModal">
              <ValidationObserver ref="observer" isFromTable="true">
                <slot name="modal" ref="modal"></slot>
              </ValidationObserver>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="crud && canDelete" color="error" dark @click.native="save">
            <v-icon>fa-trash</v-icon>Excluir
          </v-btn>
          <v-btn color="primary" @click.native="closeModal">
            <v-icon>fa-times</v-icon>Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TABELA --->
    <v-app id="inspire">
      <v-alert
        :value="errorList"
        color="error"
        type="error"
      >Ocorreu um erro ao obter os dados, tente novamente mais tarde</v-alert>
      <div v-if="!devexpress">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:[getHeaderName(h)]="{ header }" v-for="h in headers">{{header.text}}</template>

          <template v-slot:item.options="{ item }">
            <div v-if="crud">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon small class="mr-2" color="teal" v-on="on" @click="editItem(item)">fa-edit</v-icon>
                </template>
                <span>Alterar {{config.title}}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    color="pink"
                    v-on="on"
                    @click="deleteItem(item)"
                  >fa-trash-alt</v-icon>
                </template>
                <span>Excluir {{config.title}}</span>
              </v-tooltip>
            </div>
            <!-- <v-divider v-if="customButtons" class="mx-7" inset vertical></v-divider> -->
            <v-list-item-action v-for="btn in customButtons" :key="btn.id">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon small color="teal" v-on="on" @click="btn.action(item)">{{btn.icon}}</v-icon>
                </template>
                <span>{{btn.tip}}</span>
              </v-tooltip>
            </v-list-item-action>
          </template>
          <template slot="no-data">
            <div v-show="noResult">Sem informações para exibir!</div>
          </template>
        </v-data-table>

        <v-container>
          <v-layout justify-center>
            <v-card>
              <v-card-text>
                <div class="text-center">
                  <v-pagination
                    @input="pageChange"
                    :circle="true"
                    :length="length"
                    :total-visible="itensPaginacao"
                    v-model="pagination.pagination.page"
                    prev-icon="fa-arrow-circle-left"
                    next-icon="fa-arrow-circle-right"
                  ></v-pagination>
                </div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-container>
      </div>
      <!--Tabela DevExpress -->
      <div v-if="devexpress">
        <dx-data-grid
          id="gridContainer"
          :key="config.keyField"
          :data-source="itemsDx"
          :remote-operations="remoteOperations"
          @row-prepared="rowPrepared"
          
        >
          <!-- Agrupamento temporariamente desabilitado
          <dx-grouping :context-menu-enabled="true" expand-mode="rowClick" />
          <dx-group-panel
            :visible="true"
            empty-panel-text="Clique na coluna e arraste aqui para agrupar pela mesma."
          />
          -->
          <!-- Filtro das colunas temporariamente desabilitado
          <dx-header-filter :visible="true" />
          -->

          <dx-pager :show-navigation-buttons="true" :visible="true" />
          <dx-paging :page-size="pagination.pagination.resPerPage" />
          <dx-filter-row :visible="true" :apply-filter="currentFilter" />
          <dx-column-chooser
            :enabled="true"
            mode="select"
            title="Colunas disponíveis"
            emptyPanelText="Sem colunas para exibir"
          />

          <dx-editing :use-icons="true" mode="row" />
          <dx-column caption="Opções" :buttons="editButtons" type="buttons" v-if="crud" dataType="string"/>
          <!--<dx-column caption="Opções" :buttons="customButtons" type="buttons" v-if="customButtons" dataType="string"/>-->
          <dx-column
            v-for="column in headersDx"
            :key="column.dataField"
            :width="column.width"
            :allow-grouping="true"
            :data-field="column.dataField"
            :dataType="column.dataType"
            :caption="column.caption"
            css-class="custom"
          />
          <DxLoadPanel text="Carregando" />
        </dx-data-grid>
      </div>
    </v-app>

    <v-wait :dialog="loading" mensagem="Aguarde, carregando"></v-wait>
  </div>
</template>

<script>
/* eslint-disable */
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxEditing,
  DxGrouping,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxColumnChooser,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxSummary,
  DxTotalItem,
  DxScrolling,
  DxLoadPanel
} from "devextreme-vue/data-grid";
import VWait from "../../components/screen/MdWait.vue";
import CustomStore from "devextreme/data/custom_store";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxExport,
    DxEditing,
    DxGrouping,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxColumnChooser,
    DxFilterRow,
    DxHeaderFilter,
    DxSearchPanel,
    DxSummary,
    DxTotalItem,
    DxScrolling,
    DxLoadPanel,
    VWait
  },
  props: {
    preFiltroGenerico: { type: Boolean, default: false },
    devexpress: { type: Boolean, default: false },
    config: { type: Object, required: true },
    parentRefs: { type: Object, required: true },
    customButtons: { type: Array },
    crud: { type: Boolean, default: true },
    canCreate: { type: Boolean, default: true },
    editCrud: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: true },
    reload: { type: Boolean, default: false },
    preFiltro: { type: Object },
    callPreFiltroClick: { type: String, default: ''},
    preFiltroVisible: { type: Boolean, default: false },
    modalPersistent: { type: Boolean, default: false },
    refrash: {type: Number, default: -1},
    modalCallback: {type: Function, default: null},
    saveEnd: {type: Function, default: null},
    saveStart: {type: Function, default: null},
  },
  data() {
    const applyFilterTypes = [
        {
          key: "auto",
          name: "Immediately"
        },
        {
          key: "onClick",
          name: "On Button Click"
        }
      ],
      currentFilter = applyFilterTypes[0].key;
    return {
      formTitle: '',
      remoteOperations: true,
      applyFilterTypes,
      currentFilter,
      search: "",
      focusRowEnabled: false,
      searchError: "",
      confirmDelete: false,
      editedIndex: -1,
      items: [],
      itemsDx: {},
      dialog: false,
      erroSaving: false,
      errorList: false,
      loading: false,
      noResult: false,
      length: 0,
      skipPage: 0,
      pagination: {
        filter: [],
        sort: null,
        group: null,
        pagination: {
          resPerPage: 10,
          descending: true,
          sortBy: null,
          page: 1,
          pageSize: 10
        }
      },
      focusedRowKey: 0,
      timer: null,
    };
  },
  watch: {
    dialog(isOpen) {
      if(isOpen) {
        this.cleanIterval();
      } else {
        this.refrashWithTimer();
      }

    }
  },
  computed: {
    headers() {
      let auxHeader = [...this.config.header];
      auxHeader.unshift({
        text: "Opções",
        actions: true,
        sortable: false,
        value: "options"
      });
      return auxHeader;
    },
    headersDx() {
      let auxHeader = [...this.config.headerDx];
      return auxHeader;
    },
    editButtons() {
      let auxBotoes = [];
      if (this.customButtons != null && this.customButtons.length > 0)
        auxBotoes = [...this.customButtons];

      if (this.crud) {
        if (this.canDelete){
          auxBotoes.unshift(
            {
              hint: "Excluir",
              icon: "trash",
              visible: true,
              onClick: this.deleteItem,
            }
          );
        }
        if (this.editCrud){
          auxBotoes.unshift(
            {
              hint: "Alterar",
              icon: "edit",
              visible: this.editCrud,
              onClick: this.editItem,
            });
        }
      }
      return auxBotoes;
    }
  },
  methods: {
    novo(){
      this.formTitle = "Adicionar";
      this.config.isUpdateModal = false;
      this.config.isDeleteModal = false;
      this.config.isInsertModal = true;
      if ("onModelInsert" in this.config) this.config.onModelInsert();
    },
    //Evento que vai receber o aviso caso o usuario selecionar outra empresa
    changeEmpresa(){
      this.dialog = false;
      this.confirmDelete = false;
      //this.cleanModalData();
      this.resetDataInfo();
      if (!this.preFiltro) {
        this.getAllData();
      } else {
        this.preFilterClick();
      }
    },
    rowPrepared(row) {      
      if(row.rowType === 'data') {
        if ("customStyleRowDx" in this.config) {
          let style = this.config.customStyleRowDx(row.data) || ''
          row.rowElement.setAttribute("style", style);
        }
        
      }
    },
    filter(customButtons) {      
      
      this.searchError = "";
      this.loading = true;

      let self = this;
      this.itemsDx = new CustomStore({
        key: self.config.keyField,
        onLoaded: async (response) => {
           if(customButtons && customButtons.onExecute) {
              await customButtons.onExecute(response.data, self.pagination)
              customButtons = null
            }
        },
        load: async function(loadOptions) {
          let filterArray = loadOptions.filter || [];
          if (typeof filterArray[0] === "string") {
            filterArray = [[filterArray[0], filterArray[1], filterArray[2]]];
          }

          if (self.preFiltro) {
            Object.keys(self.preFiltro).forEach(function(key) {
              let val = self.preFiltro[key];
              if (val) {
                let join = "=";
                let value = self.preFiltro[key];
                if (key.indexOf("dataInicial:") >= 0) {
                  join = "dataInicial";
                  key = key.replace("dataInicial:", "");
                }
                if (key.indexOf("dataFinal:") >= 0) {
                  join = "dataFinal";
                  key = key.replace("dataFinal:", "");
                }
                if (key.indexOf("dataHoraInicial:") >= 0) {
                  join = "dataHoraInicial";
                  key = key.replace("dataHoraInicial:", "");
                  value = value + " 00:00:00";
                }
                if (key.indexOf("dataHoraFinal:") >= 0) {
                  join = "dataHoraFinal";
                  key = key.replace("dataHoraFinal:", "");
                  value = value + " 23:59:59";
                }
                if (key.indexOf("custom:") >= 0) {
                  join = "custom";
                  key = key.replace("custom:", "");
                }
                if (key.indexOf("contains:") >= 0) {
                  join = "contains";
                  key = key.replace("contains:", "");
                }
                if (key.indexOf("inNumeric:") >= 0) {
                  join = "inNumeric";
                  key = key.replace("inNumeric:", "");
                }

                filterArray.push([key, join, value]);
              }
            });
          }
          //Pré-filtro customizado em caso de necessidade
          if ("onBeforeFilter" in self.config){
            filterArray = self.config.onBeforeFilter(filterArray);
          }

          
          self.pagination.filter = filterArray;
          self.pagination.sort = loadOptions.sort;
          self.pagination.group = loadOptions.group;
          self.pagination.pagination.page =
            (loadOptions.skip / self.pagination.pagination.resPerPage) + 1;
      

          return await self.config.filter(self.pagination).then(
            r => {
              //console.log(r);
              let ret = {
                key: self.config.keyField,
                totalCount: r.pagination.recordCount,
                data: r.data,
                skip: loadOptions.skip,
                order: loadOptions.sort,
                group: loadOptions.group,
                summary: [170, 20, 20, 1020],
                groupCount: 1000,
                filter: loadOptions.filter
                  ? JSON.stringify(loadOptions.filter)
                  : ""
              };

              self.setItem(r);
              self.loading = false;

              return ret;
            },
            e => {
              self.searchError = e.response.data.message;
              self.loading = false;
            }
          );
        }
      });
    },
    async preFilterClick(customButtons) {
      this.filter(customButtons);
      this.showResult = true;

      this.pagination.pagination.page = 1;

      this.$emit("preFiltro");
    },
    setItem(r) {
      this.length = r.totalPages;
      this.focusRowEnabled = true;
      this.noResult = this.itemsDx.length == 0;
    },
    pageChange() {
      //console.log("pageChange");
      this.config.getAll(this.pagination).then(r => {
        this.setItem(r);
      });
    },
    deleteItem(e) {
      let item = null;
      if (this.devexpress) {
        item = e.row.data;
      } else {
        item = e;
      }
      this.config.isDeleteModal = true;
      this.config.isUpdateModal = false;
      this.config.isInsertModal = false;

      this.backError = null;
      this.editedIndex = this.items.indexOf(item);
      this.config.editedItem = Object.assign({}, item);
      this.confirmDelete = true;
    },
    editItem(e) {      
      let item = null;
      if (this.devexpress) {
        item = e.row.data;
      } else {
        item = e;
      }

      this.formTitle = "Alterar";
      this.config.isUpdateModal = true;
      this.config.isDeleteModal = false;
      this.config.isInsertModal = false;
      this.config.editedItem = Object.assign({}, item);
      if ("onModelEdit" in this.config) this.config.onModelEdit(item);
      this.dialog = true;
    },
    closeModal(isOutside, canClose) {
      if(this.modalCallback && isOutside !== true && !canClose) {
        this.modalCallback('close', this.closeModal)
        return;
      }
      if(isOutside === true){
        this.dialog = true &&  this.modalPersistent;
        return;
      }
      this.dialog = false;
      this.confirmDelete = false;

      this.cleanModalData();
      this.resetDataInfo();
      if (!this.preFiltro) {
        this.getAllData();
      } else {
        this.preFilterClick();
      }
    },
    cleanModalData() {
      this.config.isDeleteModal = false;
      this.config.editedItem = Object.assign({}, this.config.defaultItem);
      this.editedIndex = -1;
      this.parentRefs.refsManutencao.cleanFormValidation();
    },
    async save() {
      this.erroSaving = false;
      if(this.saveStart)  this.saveStart()

      let isValid = await this.formValidation();

      if (!isValid) {
        return false;
      }
      this.loading = true;
      if ("onModelBeforeSave" in this.config){
        this.config.onModelBeforeSave(this.config.editedItem);
      }
      this.config.save(this.config.editedItem).then(
        r => {
          this.closeModal(null, true);
          if (this.saveEnd) this.saveEnd(this.config.editedItem);
        },
        error => {
          this.loading = false;
          this.erroSaving = error.response.data;
          //if(this.saveEnd)  this.saveEnd()
        }
      );
    },
    resetDataInfo() {
      this.errorList = false;
      this.erroSaving = false;
      this.noResult = false;
      this.loading = false;
      this.items = [];
      this.pagination = {
        filter: {},
        pagination: {
          resPerPage: 10,
          descending: true,
          sortBy: null,
          page: 1,
          pageSize: 10
        }

      };
    },
    fillData(data) {
      return data.content.map(item => {
        return { options: "", ...item };
      });
    },
    async getAllData() {
      //console.log("getAllData", this.crud);
      if (!this.devexpress) {
        this.loading = true;
        this.errorList = false;
        this.noResult = true;
        this.pagination.pagination.page = 1;
        await this.config.getAll(this.pagination).then(
          data => {
            this.items = this.fillData(data);
            this.loading = false;
            //console.log(data);
            this.length = data.totalPages;
            this.itensPaginacao = 15;
            this.noResult = data.length == 0;
          },
          error => {
            this.errorList = true;
            this.loading = false;
          }
        );
      } else {
        //console.log(this.items)
        this.filter();
        this.showResult = true;
        this.pagination.pagination.page = 1;
      }
    },

    getHeaderName(h) {
      return "header." + h.value;
    },
    onFocusedRowChanging(e) {
      var pageSize = e.component.pageSize(),
        pageIndex = e.component.pageIndex(),
        isLoading = e.component.getController("data").isLoading(),
        key = e.event && e.event.key;

      if (!isLoading) {
        if (key && e.prevRowIndex === e.newRowIndex) {
          if (e.newRowIndex === pageSize - 1) {
            e.component.pageIndex(pageIndex + 1).done(function() {
              e.component.option("focusedRowIndex", 0);
            });
          } else if (e.newRowIndex === 0) {
            e.component.pageIndex(pageIndex - 1).done(function() {
              e.component.option("focusedRowIndex", pageSize - 1);
            });
          }
        }
      }
    },
    onFocusedRowChanged: function(e) {
      var data = e.row && e.row.data;

      this.config.FocusedRow = data;
      //console.log(this.config.FocusedRow);
      this.focusedRowKey = e.component.option("focusedRowKey");
    },
    reloadFilter() {      
      if (!this.preFiltro) {
        this.filter();
      } else if (this.preFiltroVisible && this.preFiltro) {
        this.preFilterClick();
      }
    },
    cleanIterval() {
      if(this.timer) {
        clearInterval(this.timer);
      }
    },
    refrashWithTimer() {
      this.cleanIterval();
      if(this.refrash && this.refrash !== -1) {
        this.timer = setInterval(this.reloadFilter, this.refrash);
      } 
    }
  },
  beforeDestroy() {
    this.cleanIterval();
    //Desregistrando o evento para essa tela
    this.$root.$off("changeEmpresa");
    if (this.callPreFiltroClick){
      this.$root.$off(this.callPreFiltroClick);
    }
  },
  created() {
    this.pagination.pagination.page = 1;
    if (!this.preFiltro)
      this.reloadFilter();
    this.refrashWithTimer();
  },
  mounted(){
    //Escutando o evento de troca de empresa
    this.eventEmpresa = (valor) => {
      this.changeEmpresa(valor);
    };
    this.$root.$on("changeEmpresa", this.eventEmpresa);
    if (this.callPreFiltroClick){
      this.$root.$on(this.callPreFiltroClick, this.preFilterClick);
    }
    //    
  }
};
</script>

<style scoped>
.v-icon {
  margin-right: 10px;
}
.dx-datagrid {
  font: 8px verdana;
  font: 8px verdana;
  font-size: 50px;
}
.custom[role="columnheader"] {
  font-size: 12px;
}
.custom[role="gridcell"] {
  font-size: 8px;
}
#app {
  margin-top: 0px !important;
}
.customButtons {
  margin-left: 10px;
}

</style>