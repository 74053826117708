import Vue from 'vue'
import Vuex from 'vuex'
//import loockups from './loockup.store'
//import contratante from './contratante.store'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        //contratante,
        //loockups
    }
})