/* eslint-disable */

import BasicService from "../../../lib/api/CRUD/BasicService";
import ArrayStore from 'devextreme/data/array_store';


export default class PedidosModel extends BasicService{
    constructor(){
        super('adm')

        this.pedidos = [];
        this.pedidosStore = new ArrayStore({
            key: "id",
            data: this.pedidos,
          });
  
    }
    
    async getStatusPedido(obj){
        return super.getWithOutPath(`statuspedido/list`,obj).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }


    async getPedidos(obj){        
        const params = {
            quantidade: obj.totalChats,
            filter: obj.filter
        }
        return super.postWithPath(`pedidos/list`,params).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }
    async getMidias(obj){

        return super.postWithPath(`midias/list`,obj).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }
    async enviarPdf(obj){
        return super.postWithPath(`pedido/sendWhats`,obj).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }
    async finalizarPedido(obj){
        return super.postWithPath(`pedido/finalizar/${obj.id}`,{}).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }

    async cancelarPedido(obj){
        return super.postWithPath(`pedido/cancelar/${obj.id}`,{motivo:'Cancelado pela plataforma'}).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }

    async pagarPedido(obj){
        return super.postWithPath(`pedido/confirmaPagto/${obj.id}`,{}).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }    
    async iniciarPedido(obj){
        return super.postWithPath(`pedido/iniciar/${obj.id}`,{}).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }

}
