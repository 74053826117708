import Vue from 'vue';
import VueTheMask from 'vue-the-mask'
import VueSimpleAlert from "vue-simple-alert";
import AudioVisual from 'vue-audio-visual'
import VueCoreVideoPlayer from 'vue-core-video-player'


import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

Vue.use(VueTheMask);
Vue.use(VueSimpleAlert);
Vue.use(AudioVisual);
Vue.use(VueCoreVideoPlayer);
