
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify';

//import { library } from '@fortawesome/fontawesome-svg-core'
//import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//library.add(faUserSecret)

//Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  icons:{
    iconfont: 'fa'
  }
}

export default new Vuetify(opts)
