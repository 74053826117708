/* eslint-disable */

import TableScreen from "@/lib/components/table/TableScreen";

export default class Profissionais extends TableScreen {

    constructor(store) {

        //Nome do model

        super('profissionais', 'aqui_vai_o_endpoint_do_export');

        this.$store = store;

        //aqui os campos que serão exibidos no header do grid
        this.headerDx = [

            { caption: "Nome", dataField: "nome", dataType: "string" },
            { caption: "Email", dataField: "login", dataType: "string" },
            { caption: "Bloqueado", dataField: "bloqueado", dataType: "string" },
        ];

        this.defaultItem = {};

        this.editedItem = {};

        //Título da tela

        this.title = 'Usuarios';
        this.keyField = "id";
    }

    onModelEdit(item){
        //console.log("senha", item.senha)
        //console.log("senha", Buffer.from(item.senha, 'base64').toString('ascii') );
    }

    onModelInsert(){
        console.log("onInsert")
    }
    
    onModelBeforeSave(item){
        console.log("beforeSave", item);
    }

    save(item) {
        return super.save(item)
    }

    async fetchProfissionais(){
        const body = {
            pagination: {
                page: 1,
                resPerPage: 9999
            }
        }
        return this.service.postWithOutPath(`profissionais/list`,body).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res.data);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }    

    async designarProfissional(obj){
        return this.service.postWithOutPath(`adm/pedido/designarProfissional`,obj).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res.data);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }    
    async cancelarProfissional(obj){
        const body = {
            id_pedido: 1,
            motivo: ''
        }
        return this.service.postWithOutPath(`adm/pedido/cancelarProfissional`,obj).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res.data);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: error.data});
            }
        )
    }    
}