<template>
    <div id="v-fields-servicos">
      <v-layout row wrap>
        <v-flex xs12>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="descricao"
            :validation="['required']"
            name="descricao"
            label="Descrição"
            v-model="gridConfig.editedItem.descricao"
          ></MdText>
        </v-flex>
        <v-flex xs12>
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                fa-question-circle
              </v-icon>
            </template>
            <span>Texto explicativo do que faz o serviço</span>
          </v-tooltip>
          <v-textarea
          :disabled="gridConfig.isDeleteModal"
          outlined
          ref="descritivo"
          name="descritivo"
          label="Descritivo"
          v-model="gridConfig.editedItem.descritivo"
        ></v-textarea>
        </v-row>
      </v-flex>
        <v-flex xs4>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="custo"
            :validation="['required']"
            name="custo"
            label="Valor"
            v-model="gridConfig.editedItem.custo"
          ></MdText>
        </v-flex>
        <v-flex xs4>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="deslocamento"
            :validation="['required']"
            name="deslocamento"
            label="Deslocamento"
            v-model="gridConfig.editedItem.deslocamento"
          ></MdText>
        </v-flex>
        <v-flex xs4>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="custo_app"
            :validation="['required']"
            name="custo_app"
            label="Custo app"
            v-model="gridConfig.editedItem.custo_app"
          ></MdText>
        </v-flex>
        <v-flex xs4>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="minimo_horas"
            :validation="['required']"
            name="minimo_horas"
            label="Mínimo horas"
            v-model="gridConfig.editedItem.minimo_horas"
          ></MdText>
        </v-flex>
        <v-flex xs4>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="maximo_horas"
            :validation="['required']"
            name="maximo_horas"
            label="Máximo horas"
            v-model="gridConfig.editedItem.maximo_horas"
          ></MdText>
        </v-flex>
        <v-flex xs4>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="minimo_dias"
            :validation="['required']"
            name="minimo_dias"
            label="Mínimo dias"
            v-model="gridConfig.editedItem.minimo_dias"
          ></MdText>
        </v-flex>
        <v-flex xs12>
          <MdText
            :disabled="gridConfig.isDeleteModal"
            ref="thumbnail"
            :validation="['required']"
            name="thumbnail"
            label="Imagem"
            v-model="gridConfig.editedItem.thumbnail"
          ></MdText>
        </v-flex>
        <MdSwitch
            v-model="gridConfig.editedItem.ativo"
            label="Ativo"
            name="ativo"
            :trueValue=true
            :falseValue=false
            :disabled="gridConfig.isDeleteModal"
          ></MdSwitch>
  
      </v-layout>
    </div>
  </template>
  
  <script>
  export default {
    name: "v-fields-servicos",
  
    props: {
      gridConfig: {},
  
      exclusao: {
        type: Boolean,
      },
    },
  
    data() {
      return {};
    },
  
    created() {},
  
    methods: {
    },
  };
  </script>
  
  <style>
  </style>
  