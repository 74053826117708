import moment from 'moment';

export default (type, value) => ({
  lessToDay() {
    const digitada = moment(value).format('YYYYMMDD');
    const data = moment(new Date()).format('YYYYMMDD');
    return digitada < data;
  },
  isNotEquals() {
    return value.value !== value.otherValue;
  },
  isBefore() {
    if (value.otherValue === undefined) {
      return true;
    }
    const data = moment(value.value);
    const otherDate = moment(new Date(value.otherValue));
    return !otherDate.isValid() || data > otherDate;
  },
  hour() {
    const match = value.split(':');
    return match.length === 2 && match[0] < 24 && match[1] < 60;
  },
  email() {
    // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  },
  custCode() {
    const re = /^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$/;
    return re.test(value);
  },
  name() {
    return value && value.split(' ').length > 1;
  },
  phone() {
    return value.length === 10 && value.charAt(2) < '8';
  },
  cel() {
    return value.length === 11 && value.charAt(2) > '7';
  },
  celPhone() {
    return this.phone() || this.cel();
  },
  cpfCnpj() {
    return value.length === 11 ? this.cpf() : this.cnpj();
  },
  registration() {
    const re = /^[0-9]+$/;
    return re.test(value);
  },
  newPassword() {
    // eslint-disable-next-line
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\?!@_#\$%\^&\*\.])(?=.{8,})/;
    return re.test(value);
  },
  curve() {
    const re = /[A-F]/;
    return value ? re.test(value) : true;
  },
  cpf() {
    let numeros;
    let digitos;
    let soma;
    let i;
    let resultado;
    let digitosIguais;

    digitosIguais = 1;
    if (value.length < 11) {
      return false;
    }
    for (i = 0; i < value.length - 1; i++) {
      if (value.charAt(i) !== value.charAt(i + 1)) {
        digitosIguais = 0;
        break;
      }
    }
    if (!digitosIguais) {
      numeros = value.substring(0, 9);
      digitos = value.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) {
        soma += numeros.charAt(10 - i) * i;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado.toString() !== digitos.charAt(0).toString()) {
        return false;
      }
      numeros = value.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) {
        soma += numeros.charAt(11 - i) * i;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado.toString() !== digitos.charAt(1).toString()) {
        return false;
      }
      return true;
    }
    return false;
  },
  cnpj() {
    if (
      !value
        || value.length !== 14
        || value === '00000000000000'
        || value === '11111111111111'
        || value === '22222222222222'
        || value === '33333333333333'
        || value === '44444444444444'
        || value === '55555555555555'
        || value === '66666666666666'
        || value === '77777777777777'
        || value === '88888888888888'
        || value === '99999999999999'
    ) {
      return false;
    }
    let tamanho = value.length - 2;
    let numeros = value.substring(0, tamanho);
    const digitos = value.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(0).toString()) return false;
    tamanho += 1;
    numeros = value.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(1).toString()) return false;
    return true;
  }
}[type]());
