<template>
  <v-main>
    <v-container fluid fill-height>
      <v-alert :value="true" color="warning" type="warning" v-if="erro"
      >Impossível atualizar</v-alert>
      <v-flex d-flex>
        <v-layout wrap>
            <v-flex v-for="item in this.dados" :key="item.tipo">
                <v-card class="card-container">
                  <v-card-title class="black--text">
                    <v-row justify="center">
                      <span class="headline">{{ item.label }}</span>
                    </v-row>                    
                  </v-card-title>
                  <v-card-actions class="white justify-center" style="overflow-x:auto; white-space: nowrap;">
                    {{ item.total }}
                  </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable */
import HomeModel from "./HomeModel.js"; 

export default {
  name: 'Home',
  props: {
    msg: String
  },
  data() {
    return {
      service: new HomeModel(this.$store),
      timer: "",
      erro: false,

      dados: []
    }
  },
  created() {
    this.atualizarIndicadores();
    this.timer = setInterval(this.atualizarIndicadores, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted(){

  },
  methods: {
    async atualizarIndicadores() {
      await this.service.refresh()
      .then( async (response) =>  {
        this.erro = false;
        //console.log(response.data);
        this.dados = response.data;
      })
      .catch((error) => {
        console.log(error);
          this.erro = true;
      });
    }
  },
  


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flexcard {
 display: flex;
 flex-direction: column; 
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
