import BasicService from '../../lib/api/CRUD/BasicService'

export default class UserModel {

    async changeData(user){
        return this.save(user,'update');
    }

    async chagePass(user){
        return this.save(user,'changePass');
    }

    async save(user, url){
        const service = new BasicService('login')
        return await service.post(url, user); 
    }
}