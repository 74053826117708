<template>
    <v-main>
    
        <v-container fluid>
    
            <v-slide-y-transition mode="out-in">
    
                <v-layout column align-left>
    
                    <h1>Cadastro de {{gridConfig.title}}</h1>                    
                    <MdTable :devexpress="true" :config="gridConfig" :parentRefs="$refs">
    
                        <template slot="modal">
    
                            <fields  ref="refsManutencao" :gridConfig="gridConfig" :exclusao=false />
    
                        </template>
    
                        <template slot="modal-excluir">
    
                            <fields ref="refsExclusao" :gridConfig="gridConfig" :exclusao=true />
    
                        </template>
    
                    </MdTable>
    
                </v-layout>
    
            </v-slide-y-transition>
    
        </v-container>
    
    </v-main>
    </template>
    
    <script>
        /* eslint-disable */
        import ServicosModel from "./ServicosModel.js";
    
        import fields from "./ServicosFields"
    
        import MdTable from "@/lib/components/table/MdTable";
    
        export default {
        name: "Servicos",
    
        components: {
            MdTable,
            fields
        },
    
        data() {
            return {
                gridConfig: new ServicosModel(this.$store),
                modalPermite: true,
                modalNPermite: false,
                listaAgentes: []
            };
        },
      computed:{
        getRefs(){
            return this.$refs;
          },
      },
      methods:{
      },
      mounted() {
      }
    };
    </script>
    
    <style>
    </style>
    