import Vue from 'vue'


const customMixin = {
    methods: {
        cleanFormValidation() {
            Object.keys(this.$refs).forEach(e => {
                //console.log(this.$refs[e]);
                try {
                    this.$refs[e].$refs.provider.reset();
                } catch (e){
                    //switch não tem reset..deu um jeito assim
                }
            })
        },

        formValidation: async function(){
            let hasFocus = false;
            let observer = this.$refs.observer;
            let isValid = await observer.validate();
            if(!isValid) {

                let observerChildren = this.$refs;
                
                //pagina que possue os provider do vee-validate 
                let page = this

                if(observer.$attrs.isFromTable) { 
                    // o vee validate está sendo chamado pela api do FollowTable
                    page = observer.$children[0]; //obtem a página que está sendo usado no modal
                    observerChildren = page.$refs;
                }

                Object.keys(observerChildren).forEach(async e=> {
                    let provider = page.$refs[e];
                    let field = provider.$refs.input
                    if(!hasFocus && field && field.externalError) {
                        hasFocus = true;
                        field.focus()
                    }
                    
                })
            }
            
            return isValid;
        }
    }
}

Vue.mixin(customMixin)
