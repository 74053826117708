import Vue from 'vue';
import VueRouter from 'vue-router'
import { API_NAME_TOKEN } from './lib/api/apiConfig'

import routesCustom from './routesCustom.js'

import Home from './pages/home/Home'
import Login from './pages/login/Login'
import ChangePass from './pages/avatar/ChangePass'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/changePass',
        name: 'ChangePass',
        component: ChangePass
    },
        
    { path: '*', redirect: '/' },
    ...routesCustom

]


const router = new VueRouter({routes})

router.beforeEach((to, from, next) => {
    if (to.name == from.name) {
        return
    }
   // }
    /*if(to.name !== 'login' && to.name !== 'Home') {
        let contratante = ContratanteStore.getters.contratante();

        if (!contratante) {
            next({name: 'Home'})
            return;
        }

    }*/

    if (to.name === 'login') {
        if (localStorage.getItem(API_NAME_TOKEN))
            localStorage.removeItem(API_NAME_TOKEN);
        if (localStorage.getItem('md-user.Admin'))
            localStorage.removeItem('md-user.Admin');

    }
    next()
});
export default router