<template>
    <div>
      <v-wait :dialog="processando" mensagem="Aguarde, processando..."></v-wait>
        <span class="headline">Selecione um profissional</span>
        <div class="form-group">
            <v-card >
            <v-alert :value="erro!=''" color="error" type="error">Erro ao atribuir profissional: {{msgErro}}</v-alert>
            <MdSelect
                :autocomplete=true
                ref="idProfissional"
                name="idProfissional"
                label="Profissional"
                :validation="['required']"
                v-model="id_profissional"
                :items="profissionais"
                itemText="nome"
                itemValue="id"
                outlined
                ></MdSelect>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="id_profissional==''" color="primary" @click.native="confirmar">
              <v-icon>fa-book</v-icon>Confirmar
            </v-btn>
            <v-btn color="primary" @click.native="fechar">
              <v-icon>fa-times</v-icon>Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import model from './ProfissionaisModel.js';
import MdText from '@/lib/components/fields/MdText.vue';
import MdSelect from '@/lib/components/fields/MdSelect.vue';
import VWait from "@/lib/components/screen/MdWait.vue";

export default {
    name: "formTrocaOperador",
    components:{
        MdText,
        MdSelect,
        VWait
    },

    props:{
    },
    data() {
        return {
            model: new model(this.$store),
            erro: false,
            profissionais: [],
            id_profissional: "",
            idPedido: "",
            msgErro : "",
            processando: false,
        }
    },
    methods:{

        async carregarProfissionais(){
            //console.log('vai carregar');
            this.profissionais = await this.model.fetchProfissionais();
            console.log(this.operadores);
        },

        async confirmar(){
            this.processando = true;
            await this.model.designarProfissional({id_pedido: this.idPedido, id_profissional: this.id_profissional,fechado:true,lista:false,filter:[['id','=',this.idPedido]]})
            .then( async (response) =>  {
                //console.log("chagneOperadorData",response);
                this.processando = false;
                this.$root.$emit("changeProfissionalPedido", {id_profissional: this.id_profissional});
            })
            .catch((error) => {
                this.processando = false;
                console.log(error);
                this.msgErro = error.error.message.toString();
                this.erro = true;
            });
            
        },
        fechar(){
            this.$root.$emit("changeProfissionalPedido");
        },
        setouOperadortrocaChat(obj){
            //console.log('setou',obj);
            this.erro = false;
            this.id_profissional = obj.idProfissional;
            this.idPedido = obj.idPedido;
            this.$refs.idProfissional.value = obj.idProfissional;
        }

    },
    mounted() {
        this.erro = false;        
        this.carregarProfissionais();
    }

}
</script>

<style lang="scss"> 
@media only screen and (max-width: 600px) {
  .mensagens-cards{
    .headline {
        font-size: 15px !important;
    }
    .dx-datagrid-text-content {
      font-size: 10px !important;
    }
    td {
      font-size: 10px !important;
      height: 10px !important;
    }
    tr {
      font-size: 10px !important;
      height: 10px !important;
    }
     
  }
  .filter {
    
      .v-text-field__slot {
            font-size: 10px !important;
      }
      fieldset {
            height: 40px !important;
      }
    }
    
}

</style>