import Vue from 'vue';

import { ValidationProvider, extend, localize, ValidationObserver } from 'vee-validate';
import validator from './validator';
import * as rules from 'vee-validate/dist/rules';
//import pt_BR from 'vee-validate/dist/locale/pt_BR.json';


const dictionary = {
  br: {
    messages: {
      confirmed: () => 'O campo não corresponde com o anterior',
      required: () => 'O campo é obrigatório.',
      length: (value, args) => `O campo deve conter ${args} caracteres!`,
      alpha_num: () => 'O campo só aceita caracteres alfanuméricos',
      min: (value, args) =>  `O campo deve conter no mínimo ${args.length} caracteres!`,
      email: () => 'O email não é valido'
    }
  }
};

const removeMask = (value) => {
  const match = value.match(/\d/g);
  if (match) {
    return match.join('');
  }
  return '';
};

for (let rule in rules) {
  extend(rule, rules[rule]);
}

extend('isNotEquals', {
  getMessage: () => 'Campo Inválido',
  validate: (value, [otherValue]) => validator('isNotEquals', { value, otherValue })
});

extend('isBefore', {
  getMessage: () => 'Data Inválida',
  validate: (value, [otherValue]) => validator('isBefore', { value, otherValue })
});

extend('hour', {
  getMessage: () => 'Horário inválido',
  validate: value => validator('hour', value)
});

extend('cnpj', {
  message: 'CNPJ inválido',
  validate: value => validator('cnpj', removeMask(value))
});

extend('name', {
  getMessage: () => 'Nome inválido',
  validate: value => validator('name', value)
});
extend('cpf', {
  message: 'CPF inválido',
  validate: value => validator('cpf', removeMask(value))
});

extend('lessToDay', {
  getMessage: () => 'A data informada é maior que a data atual',
  validate: value => validator('lessToDay', value)
});

extend('celPhone', {
  message: 'Telefone inválido',
  validate: value => validator('celPhone', removeMask(value))
});

extend('custCode', {
  getMessage: () => 'Codigo inválido',
  validate: value => validator('custCode', value)
});

extend('registration', {
  getMessage: () => 'Matrícula inválida',
  validate: value => validator('registration', value)
});

extend('password', {
  getMessage: () => 'Senha inválida',
  validate: value => validator('password', value)
});

extend('curve', {
  getMessage: () => 'Curva deve ser entre A-F',
  validate: value => validator('curve', value)
});

extend('newPassword', {
  getMessage: () => 'Formato de senha inválida! A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
  validate: value => validator('newPassword', value)
});



localize('pt_BR', dictionary.br);



Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);