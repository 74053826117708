import BasicService from "@/lib/api/CRUD/BasicService";
//import ContratanteService from './ContratanteService'
import Vue from "vue";

export default class LoginService extends BasicService{
    constructor(){
        super('adm/login/signIn')
    }
    
    async login({password, username}){
        if(!password || !username) return false;
        let username64 = Buffer.from(username).toString('base64');
        let pass64 = Buffer.from(password).toString('base64');
        return this.post('',{ senha: pass64, email: username64 }).then(
            async res => {
                //console.log(res);                                
                if ( (res.status != 200) || (res.data.token == "") ) {
                    return Promise.resolve(false);
                }
                Vue.$sistema.$login(res.data.token,res.data.idEmpresa, res.data.admin);

                //let empresas = await this.getWithPath('getEmpresas');
                //Vue.$sistema.$setEmpresas(empresas, res.data.idEmpresa);

                //const contratanteService = new ContratanteService();
                //const contratante = await contratanteService.getAny();
                //return Promise.resolve({contratante});
                //console.log(" OK ",res);
                return Promise.resolve(res.data);
            },
            error => {
                console.log(error);
                return Promise.reject(error); }
        )
    }

}