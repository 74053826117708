import Vue from 'vue'
import router from '../routes';
import { API_NAME_TOKEN, APP_EMPRESA_SEL } from '../lib/api/apiConfig';

export default {
    install() {
        Vue.$sistema = Vue.$sistema || {}

        Vue.$sistema.$loginListener = {
            loginListeners: [],
            logoutListeners: [],
            empresaListeners:[],

            addLogin: function (listener) {
                this.loginListeners.push(listener)
            },
            addLogout: function (listener) {
                this.logoutListeners.push(listener)
            },
            addEmpresa: function (listener){
                this.empresaListeners.push(listener)
            },
            notifyLogin: function () {
                this.loginListeners.forEach(e => {
                    e()
                })
            },
            notifyLogout: function () {
                this.logoutListeners.forEach(e => {
                    e()
                })
            },
            notifyEmpresa: function(empresas, id) {
                this.empresaListeners.forEach( e=>{
                    e(empresas, id)
                })
            }

        };

        Vue.$sistema.$login = function (token, idEmpresa, admin) {
            
            localStorage.setItem(API_NAME_TOKEN, token);
            localStorage.setItem(APP_EMPRESA_SEL, idEmpresa);
            localStorage.setItem('md-user.Admin', admin);            
            Vue.$sistema.$loginListener.notifyLogin();
        };

        Vue.$sistema.$logout = function () {
            localStorage.clear();
            Vue.$sistema.$loginListener.notifyLogout();
            router.push({name: 'login'})
        };

        Vue.$sistema.$setEmpresas = function (empresas, id) {
            Vue.$sistema.$loginListener.notifyEmpresa(empresas, id);
        };
    }

}


