/* eslint-disable */
import DAO from './DAO'

export default class BasicService extends DAO{
    constructor(url){
        super(url)
    }

    get(params) {
        return new Promise((resolve, reject) => { 
            super.get(params).then(
                response => {        
                    resolve(response.data);
                },
                error => {
                    reject(error);
                },
            );  
        });
    }
    
    getWithOutPath(path) {
        return super.getWithOutPath(path).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            },
        );

    }

    getWithPath(path) {
        return super.getWithPath(path).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            },
        );

    }

    getGeneric(path) {
        return super.getGeneric(path).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            },
        );

    }

    getWithOuPathDownload(path){
        return super.getWithOutPathDownload(path).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            },
        );
    }

    getWithPathDownload(path){
        return super.getWithPathDownload(path).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            },
        );
    }

    postWithOutPath(path, obj) {
        return super.postWithOutPath(path, obj).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {                
                return Promise.reject(error);
            },
        );
    }

    postWithPath(path, obj) {
        return super.postWithPath(path, obj).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                //console.error(error);
                return Promise.reject(error);
            },
        );

    }

    postWithPathBlob(path, obj) {
        return super.postWithPathBlob(path, obj).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            },
        );

    }

    postDownloadWithPath(path, obj) {
        return super.postDownloadWithPath(path, obj).then(
            response => {
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            },
        );

    }

    filter(obj) {
       
      return new Promise((resolve, reject) => { 
          super.filter(obj).then(
              response => {           
                  resolve(response.data);
              },
              error => {
                  reject(error);
              },
          );

      });
  }
  delete(id) {
      return super.delete(id).then(
          response => {
              return Promise.resolve();
          },
          error => {
              return Promise.reject(error);
          },
      );
  }

  list(root) {
      return new Promise((resolve, reject) => { 
          this.dao.listAll().then(
              response => {
                resolve(response.data);
              },
              error => {
                  reject(error);
              },
          );

      });
  }

  save(obj) {
      return super.post(obj).then(
          response => {   
              //console.log(" OK-save",response);         
              return Promise.resolve(response);
          },
          error => {
              //console.log(error);
              return Promise.reject(error);
          },
      );
  }
  
  post(url, obj) {
    return super.post(obj, url).then(
        response => {            
            return Promise.resolve(response);
        },
        error => {
            return Promise.reject(error);
        },
    );
  }
  
}

