//O arquivo ENV nao funcionou de jeito nenhum, entao foi assim mesmo
module.exports = Object.freeze({
    URL_LOCAL:'https://api.briefjobs.com.br',
    URL_REMOTE:'https://api.briefjobs.com.br',
    APP_ENDPOINT:'/apimobile/v1',
    APP_PORT:'28001',
    LOCAL_TOKEN:'md-briefadm',
    LOCAL_EMPRESA_SEL: 'md-brief-adm',
    APP_TITLE:"Brief Jobs",
    APP_FOOTER:"Portal Administrativo",
    APP_RELEASE_DATE: "07/06/2023",
    APP_VERSION:"1.0.0",
});
