<template>
    <div>
        <ValidationObserver ref="form">
            <ValidationProvider 
              ref="provider" 
              :name="name" 
              :rules="validationString" 
              v-slot="{ errors }">

                <v-text-field
                    v-bind="$attrs"
                    outlined
                    ref="input"
                    :error-messages="errors[0]"
                    :label="label"
                    :name="name"
                    :id="name"
                    :value="value"
                    @input="onInput"
                    :prepend-icon="prependicon"

                />
            </ValidationProvider>
        </ValidationObserver>
    </div>

</template>

<script>
    export default {
        name: 'MdText',
        data() {
            return {
                textField: "",
            }
        },
        props: {
            value: {},// eslint-disable-line
            defaultValue: {},// eslint-disable-line
            validation: {
              type: Array,
              default: null
            },
            name: {
                type: String,
                required: true
            },
            required: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            prependicon:{
                type: String,
                default: ''
            }
        },
        computed: {
          validationString() {
              let validation = this.validation || []
              if(this.required) validation.push('required')
              return validation.join('|')
          }
        },
        methods: {
            setError(msg) {
            this.$refs.form.setErrors({
                [this.name]: [msg]
            });
            },
            onInput(event) {
                if (this.disabled) { return; }
                this.$emit('input', event);
            }
        },
        mounted() {
          if(this.defaultValue) {
              this.$emit('input', this.defaultValue);
          }
        }

    }

</script>

<style>

</style>
