/* eslint-disable */

import TableScreen from "@/lib/components/table/TableScreen";

export default class Usuarios extends TableScreen {

    constructor(store) {

        //Nome do model

        super('usuarios', 'aqui_vai_o_endpoint_do_export');

        this.$store = store;

        //aqui os campos que serão exibidos no header do grid
        this.headerDx = [

            { caption: "Nome", dataField: "nome", dataType: "string" },
            { caption: "Email", dataField: "login", dataType: "string" },
            { caption: "Bloqueado", dataField: "bloqueado", dataType: "string" },
        ];

        this.defaultItem = {};

        this.editedItem = {};

        //Título da tela

        this.title = 'Usuarios';
        this.keyField = "id";
    }

    onModelEdit(item){
        //console.log("senha", item.senha)
        //console.log("senha", Buffer.from(item.senha, 'base64').toString('ascii') );
        console.log("modelEdit",item);
        this.editedItem.senha = Buffer.from(item.senha, 'base64').toString('ascii');
    }

    onModelInsert(){
        console.log("onInsert")
    }
    
    onModelBeforeSave(item){
        console.log("beforeSave", item);
    }

    save(item) {
        console.log(item);
        item.senha = Buffer.from(item.senha).toString("base64");
        return super.save(item)

    }

}