<template>
  <div id="app">
    <v-app id="inspire">

      <v-app-bar app clipped-right class="app_bar">
        <v-app-bar-nav-icon class="botao_menu" @click.stop="drawer = !drawer" v-if="isLogged"></v-app-bar-nav-icon>
        <img class="mr-3 logo" :src="require('../../assets/images/logo.png')" height="70" />
        <div class="sistema_name"> {{title}}</div>
        <v-spacer></v-spacer>      
     <v-menu offset-y rounded="b-xl" v-if="isLogged">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>fa-user</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!--<v-list-item>
          <v-list-item-title @click="redirect({link:'MyPerfil'})">Meu perfil</v-list-item-title>          
        </v-list-item>
        -->
        <v-list-item>
          <v-list-item-title @click="redirect({link:'ChangePass'})">Alterar senha</v-list-item-title>          
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>          
          <v-list-item-action>
              <v-icon>fa-sign-out-alt</v-icon>
            </v-list-item-action>
          <v-list-item-title @click.stop="logout">Sair</v-list-item-title>          
        </v-list-item>
      </v-list>
      
    </v-menu>


      </v-app-bar>

      <v-navigation-drawer v-if="isLogged" v-model="drawer" app>
        <v-list dense>
          <v-list-item @click.stop="redirect({link:'Home'})">
            <v-list-item-action>
              <v-icon>fa-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-for="(menu, i) in items"
            :key="i"
            :prepend-icon="`${menu.icon}`"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title>{{menu.text}}</v-list-item-title>
            </template>

            <v-list-item v-for="(submenu, i) in menu.children" :key="i" @click="redirect(submenu)">
              <v-list-item-title v-text="submenu.text"></v-list-item-title>

              <v-list-item-action>
                <v-icon v-text="submenu.icon"></v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>

          <v-list-item @click.stop="logout">
            <v-list-item-action>
              <v-icon>fa-sign-out-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer v-model="left" fixed temporary></v-navigation-drawer>

      <slot></slot>

      <v-main>
        <v-container fluid fill-height>
          <v-layout justify-center align-left></v-layout>
        </v-container>
      </v-main>

      <v-navigation-drawer v-model="right" fixed right temporary></v-navigation-drawer>

      <v-footer app color="#012A56" class="white--text">
        <span>{{footer}}</span>
        <v-spacer></v-spacer>
        <a class="medola_sistemas" href="https://www.medolasistemas.com.br" target="_blank">Médola Sistemas</a>
        <span>&copy; {{ano}}</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import menuConfig from "./Menu.json";
import Vue from "vue";
import { API_NAME_TOKEN, APP_TITLE, APP_FOOTER } from '@/lib/api/apiConfig.js';

export default {
  name: "Menu",
  data() {
    return {
      drawer: null,
      drawerRight: false,
      right: false,
      left: false,
      isLogged: false,
      title: APP_TITLE,
      footer: APP_FOOTER,
      items: menuConfig,
      menuProfile: true,
      item: 0,
      itemsAvatar: [
        { text: "My Files", icon: "mdi-folder", action: this.nan },
        {
          text: "Shared with me",
          icon: "mdi-account-multiple",
          action: this.nan
        },
        { text: "Starred", icon: "mdi-star", action: this.nan },
        { text: "Recent", icon: "mdi-history", action: this.nan },
        { text: "Offline", icon: "mdi-check-circle", action: this.nan },
        { text: "Uploads", icon: "mdi-upload", action: this.nan },
        { text: "Backups", icon: "mdi-cloud-upload", action: this.nan },
        { text: "Sair", icon: "mdi-exit-to-app", action: this.logout }
      ],
      razaoSocial: ''
    };
  },
  methods: {
    redirect(menu) {
      this.$router.push({ name: menu.link });
    },
    logout() {
      this.menuProfile = true;
      Vue.$sistema.$logout();
    },
    nan() {
      this.menuProfile = true;
      //console.log("clicou");
    },
    updateComponent() {
      this.isLogged = localStorage.getItem(API_NAME_TOKEN);
    },
  },
  mounted() {
    this.isLogged = localStorage.getItem(API_NAME_TOKEN);
    Vue.$sistema.$loginListener.addLogin(this.updateComponent);
    Vue.$sistema.$loginListener.addLogout(this.updateComponent);


  },
  computed: {
  ano() {
      return new Date().getFullYear();
    },
  }
};
</script>


<style lang="scss" >
@media only screen and (max-width: 600px) {
  .sistema_name {
    widows: 120px;
    font-size: 7px !important;
    }
    .contratante_name {
      margin-left: 0px !important;
      input {
        font-size: 10px !important;
      }
      
    }
    .logo {
      width: 40px;
      height: 40px;
    }
}
.header {
  color: white;
  background: #7e8630;
  background: -webkit-linear-gradient(45deg, #7e8630 0%, #aec8e2 100%);
}
.app_bar{
  color: #012A56
}
.contratante_name {
  width: 35%;
  color: white;
  margin-left: 10em;
}
.sistema_name {
  color: rgb(121, 111, 111);
  font-weight: 700;
  font-size: 150%;
}
.medola_sistemas {
  font-size: 10px;
  color: rgb(121, 111, 111);
}
</style>
