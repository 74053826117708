/* eslint-disable */

import TableScreen from "@/lib/components/table/TableScreen";

export default class Usuarios extends TableScreen {

    constructor(store) {

        //Nome do model

        super('adm/servicos', 'aqui_vai_o_endpoint_do_export');

        this.$store = store;

        //aqui os campos que serão exibidos no header do grid
        this.headerDx = [

            { caption: "Descrição", dataField: "descricao", dataType: "string" },
            { caption: "Custo", dataField: "custo", dataType: "number" },
            { caption: "Ativo", dataField: "ativo", dataType: "boolean" },
        ];
      

        this.defaultItem = {};

        this.editedItem = {};

        //Título da tela

        this.title = 'Serviços';
        this.keyField = "id";
    }

    onModelEdit(item){
        //console.log("senha", item.senha)
        //console.log("senha", Buffer.from(item.senha, 'base64').toString('ascii') );
        console.log("modelEdit",item);
    }

    onModelInsert(){
        console.log("onInsert")
    }
    
    onModelBeforeSave(item){
        console.log("beforeSave", item);
    }

    save(item) {
        //console.log(item);
        //item.senha = Buffer.from(item.senha).toString("base64");
        return super.save(item)

    }

}