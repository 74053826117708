/* eslint-disable */
import axios from 'axios';
import Vue from 'vue'
import { API_BASE_URL, API_NAME_TOKEN, APP_EMPRESA_SEL } from '../apiConfig'

export const getHeaders = function () {
  let idEmpresa = localStorage.getItem(APP_EMPRESA_SEL);
  if ( (idEmpresa == null) || (idEmpresa == "") )
    idEmpresa ="-";
  else
    idEmpresa = Buffer.from(idEmpresa).toString("base64");

  return {
    'Authorization': localStorage.getItem(API_NAME_TOKEN) + '',
    'Access-Control-Allow-Credentials': true,
    'x-empresa': idEmpresa
  }
}
export default class Resource {
  constructor(url) {
    this._url = url;
  }
  static getApiBaseUrl() {
    //console.log("URL backend:",API_BASE_URL);
    return API_BASE_URL;
  }

  errorResponseFilter(error) {
    //console.log("errorResponseFilter",error.response);
    if ((error.response.status == 401) || (error.response.status == 500) ) {      
      Vue.$sistema.$logout()
    }
  }

  successResponseFilter(res) {
    localStorage.setItem(process.env.APP_LOCAL_TOKEN, res.headers.authorization);
  }

  delete(id) {

    return new Promise((resolve, reject) => {
      axios.delete(`${Resource.getApiBaseUrl()}/${this._url}/${id}`, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    });
  }

  filter(obj) {
    return new Promise((resolve, reject) => {
      axios.post(`${Resource.getApiBaseUrl()}/${this._url}/filter`, obj, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    });
  }

  post(obj, url = 'save') {
    //console.log("BackEnd URL:",`${Resource.getApiBaseUrl()}/${this._url}/${url}`);
    return new Promise((resolve, reject) => {
      axios.post(`${Resource.getApiBaseUrl()}/${this._url}/${url}`, obj, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    });
}


  getWithPath(path) {
    return new Promise((resolve, reject) => {
      axios.get(`${Resource.getApiBaseUrl()}/${this._url}/${path}`, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    })
  }

  getGeneric(path) {
    return new Promise((resolve, reject) => {
      axios.get(`${path}`, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    })
  }

  getWithPathDownload(path) {
    return new Promise((resolve, reject) => {
      axios.get(`${Resource.getApiBaseUrl()}/${this._url}/${path}`, {
        responseType: 'arraybuffer',
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    })
  }

  getWithOutPathDownload(path) {
    return new Promise((resolve, reject) => {
      axios.get(`${Resource.getApiBaseUrl()}/${path}`, {
        responseType: 'arraybuffer',
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    })
  }

  getWithOutPath(path) {
    //console.log("ouvir->",`${Resource.getApiBaseUrl()}/${path}`);
    return new Promise((resolve, reject) => {
      axios.get(`${Resource.getApiBaseUrl()}/${path}`, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    })
  }
  postWithOutPath(path, obj) {
    return new Promise((resolve, reject) => {
      axios.post(`${Resource.getApiBaseUrl()}/${path}`, obj, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    });

  }

  postWithPath(path, obj) {
    return new Promise((resolve, reject) => {
      axios.post(`${Resource.getApiBaseUrl()}/${this._url}/${path}`, obj, {
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    });

  }


  postWithPathBlob(path, obj, responseType) {
    return new Promise((resolve, reject) => {
      axios.post(`${Resource.getApiBaseUrl()}/${this._url}/${path}`, obj, {
        responseType: {responseType: 'blob'},
        headers: getHeaders()
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    });

  }

  postDownloadWithPath(path, obj) {
    return new Promise((resolve, reject) => {
      axios.post(`${Resource.getApiBaseUrl()}/${this._url}/${path}`, obj, {
        headers: getHeaders(),
        responseType: 'blob'
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    });

  }

  get(params) {
    return new Promise((resolve, reject) => {
      axios.get(`${Resource.getApiBaseUrl()}/${this._url}`, {
        headers: getHeaders(),
        params
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    })
  }


  listAll() {
    return new Promise((resolve, reject) => {
      axios.get(`${Resource.getApiBaseUrl()}/${this._url}/`, {
        headers: {
          'Authorization': localStorage.getItem(process.env.APP_LOCAL_TOKEN) + ''
        },
      }).then(
        response => { this.successResponseFilter(response); resolve(response); },
        error => { this.errorResponseFilter(error); reject(error.response); }
      );
    })
  }
}

