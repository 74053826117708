<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>
            <ValidationObserver ref="observer">
              <v-form @submit.prevent="login" >
              <v-card-text>
                <MdText
                  ref="usuario"
                  :validation="['required']"
                  name="usuario"
                  label="Usuário"
                  v-model="model.username"
                  prependicon="fa-user"
                />
                <MdText
                  type="password"
                  ref="pass"
                  :validation="['required', 'min:6']"
                  name="pass"
                  label="Senha"
                  v-model="model.password"
                  prependicon="fa-key"
                />
                
                <v-divider></v-divider>

              </v-card-text>
            <v-card-actions>
              <small class="a">Versão: {{versao}} - {{releaseDate}}</small> 
              <v-spacer></v-spacer>
              <v-btn type="submit" color="primary" class="ma-2" @click="login">
                <v-icon>fa-sign-in</v-icon>
                Entrar
              </v-btn>
            </v-card-actions>
            </v-form>
            </ValidationObserver>

            <v-alert v-if="error" type="error" class="mb-4">Usuáro ou senha não conferem!</v-alert>
          </v-card>
          
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import LoginService from "@/lib/api/services/LoginService" 
//import { mapActions } from "vuex";
import { API_NAME_TOKEN, APP_VERSION, APP_RELEASE_DATE } from "@/lib/api/apiConfig";

export default {
  name: "SignIn",
  data() {
    return {
      error: null,
      service: new LoginService(this.$store),
      versao: APP_VERSION,
      releaseDate: APP_RELEASE_DATE,
      model: {}
    };
  },
  created() {
    localStorage.removeItem(API_NAME_TOKEN);
  },
  methods: {
    //...mapActions({
    //  setUsername: "contratante/setUsername",
    //  setContratante: "contratante/setContratante"
    //}),
    async login() {
      //await this.setContratante({});
      if (!(await this.formValidation())) {
        return false;
      }
      this.error = false;
      try {
        let response = await this.service.login(this.model);
        this.error = !response;
      } catch (e) {
        this.error = true;
      }
      if (!this.error) {
        //await this.setUsername(this.model.username);
        //console.log(response);
        //const contratante = response;

        //await this.setContratante(contratante);
        this.$router.push({ name: "Home" });
      }
    }
  }
};
</script>