<template>
  <v-main>
    <v-container fluid fill-height >
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Segurança</v-toolbar-title>
            </v-toolbar>
            <v-card-title>Alteração de senha</v-card-title>
            <ValidationObserver ref="observer">
              <v-form @submit.prevent="add">
                <v-card-text>
                  <MdText
                    type="password"
                    ref="oldPass"
                    :validation="['required', 'min:6']"
                    name="oldPass"
                    label="Senha atual"
                    v-model="model.oldPass"
                  ></MdText>
                  <MdText
                    type="password"
                    ref="newPass1"
                    :validation="['required', 'min:6']"
                    name="newPass1"
                    label="Nova senha"
                    v-model="model.newPass1"
                  ></MdText>
                  <MdText
                    type="password"
                    ref="newPass2"
                    :validation="['required', 'confirmed:newPass1', 'min:6']"
                    name="newPass2"
                    label="Repita a senha"
                    v-model="model.newPass2"
                  ></MdText>
                </v-card-text>
              </v-form>
            </ValidationObserver>
            <v-alert v-if="error" type="error" class="mb-4">{{
              error
            }}</v-alert>
            <v-alert v-if="success" type="success" class="mb-4">{{
              success
            }}</v-alert>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn :disabled="disabilitado" color="primary" class="mb-e elevation=3" @click="add"
                >Alterar</v-btn
              >
              <span v-if="disabilitado">Aguarde, redirecionando...</span>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import UserModel from "./UserModel";

export default {
  name: "changePass",
  data() {
    return {
      error: false,
      success: false,
      disabilitado: false,
      service: new UserModel(),
      model: {},
      aguardeCursor:'wait',
      defaultCursor:'default'
    };
  },
  created() {},
  methods: {
    async add() {
      if (await this.formValidation()) {
        this.error = false;
        this.success = false;
        let obj = {
            oldPass: Buffer.from(this.model.oldPass).toString('base64'),
            newPass1: Buffer.from(this.model.newPass1).toString('base64'),
            newPass2: Buffer.from(this.model.newPass2).toString('base64')
        }
        let response;
        try {
            response = await this.service.chagePass(obj);
            if (response.status != 200)
                this.error = response.statusText;
            else {
                this.disabilitado = true;
                this.success = "Senha alterada com sucesso!";
                setTimeout(() => {  this.$router.push({ name: "Home" }); }, 2000);
            }
        } catch (e) {
            this.error = "Dados inválidos. Verifique-os e tente novamente";
        }
        //if (re)
      }
    },
  },
};
</script>

<style>
.aguarde{
    cursor: wait;
}
</style>