<template>
<div id="v-wait">
    <v-dialog
        v-model="dialog"    
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            {{mensagem}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'MdWait',
    props: {
        dialog: {type : Boolean},
        mensagem: {type : String},
    },
    data() {
        return {
        }
    },
    created() {
    }
}
</script>

<style>

</style>

