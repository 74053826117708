import Vue from 'vue';
import MdText from '../lib/components/fields/MdText'
import MdSelect from "../lib/components/fields/MdSelect";
import MdDate from "../lib/components/fields/MdDate";
import MdSwitch from "../lib/components/fields/MdSwitch";
import MdFile from "../lib/components/fields/MdFile";
import MdAudioPlayer from "../lib/components/tools/MdAudioPlayer";
/*
import MdTable from "../lib/components/table/MdTable";
import MdAddress from "../lib/components/frames/address/MdAddress";
*/
Vue.component('MdText', MdText);
Vue.component('MdSelect', MdSelect);
Vue.component('MdDate',MdDate);
Vue.component('MdSwitch',MdSwitch);
Vue.component('MdFile',MdFile);
Vue.component('MdAudioPlayer',MdAudioPlayer);
/*Vue.component('MdTable', MdTable);
Vue.component('MdAddress', MdAddress);
*/
