/* eslint-disable */

import BasicService from "../../lib/api/CRUD/BasicService";


export default class HomeModel extends BasicService{
    constructor(){
        super('adm/dashboard')
    }

    async refresh(obj){
        return super.postWithPath(`list`,obj).then(
            res => {
                //console.log("ok",res)
                return Promise.resolve(res);
            },
            error => {
                //console.log("erro",error)
                //throw error;
                return Promise.reject({error: 'generic'});
            }
        )
    }
}

