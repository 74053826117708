import Usuarios from './pages/custom/usuarios/Usuarios' 
import Pedidos from './pages/custom/pedidos/Pedidos'
import Servicos from './pages/custom/servicos/Servicos'

export default 
    [{
        path: '/usuarios',
        name: 'Usuarios',
        component: Usuarios
    },
    {
        path: '/pedidos',
        name: 'Pedidos',
        component: Pedidos
    },
    {
        path: '/servicos',
        name: 'Servicos',
        component: Servicos
    }]    
