<template>
  <div>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">

  <ValidationObserver ref="form">

        <ValidationProvider
          ref="provider"
          :name="name"
          :rules="validationString"
          v-slot="{ errors }"
        >
          <v-text-field
                  v-mask="'##/##/####'"
            v-bind="$attrs"
            ref="input"
            @input="onInput"
            :value="textField"
            v-on="on"
            outlined
            :error-messages="errors[0]"
            :label="label"
            :name="name"
            :id="name"
          ></v-text-field>
        </ValidationProvider>
  </ValidationObserver>

      </template>
      <v-date-picker :value="value" no-title @input="onInputDatePicker" locale="pt-br"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
  import moment from "moment";
export default {
  name: "MdDate",
  data() {
    return {
      textField: "",
      menu1: false
    };
  },
  props: {
    value: {}, // eslint-disable-line
    defaultValue: {}, // eslint-disable-line
    validation: {
      type: Array,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    validationString() {
      let validation = this.validation || [];
      if (this.required) validation.push("required");
      return validation.join("|");
    },
  },
  mounted() {
    if(this.defaultValue) {
      this.textField = moment(this.defaultValue).format("DD-MM-YYYY");
    }
    
  },
  methods: {
    setError(msg) {
      this.$refs.form.setErrors({
        [this.name]: [msg]
      });
    },
    onInput(event) {
      if (this.disabled) {
        return;
      }
      this.$emit("input", this.formatDate(event));
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day}-${month}-${year}`;
    },
    onInputDatePicker(event) {
      this.menu1 = false;
      if (this.disabled) {
        return;
      }
      this.textField = moment(event).format("DD-MM-YYYY");
    },
    
  }
};
</script>

<style>
</style>
