/* eslint-disable */
import Vue from 'vue' 
//import ContratanteStore from "../../../api/services/ContratanteService";
import BasicService from "../../api/CRUD/BasicService";

export default class TableScreen {

    constructor(name, exportEndpoint) {
        this.service = new BasicService(name);
        this.name = name;
        this.validation = {
            gridConfig:{
                editedItem:{}
            }
        }
        this.customButtons = []
    }

    static addValidation(validation) {
        return {
            gridConfig:{
                editedItem:{
                    ...validation
                },
               
            }
        }
    }

    getAll(pagination){
        return this.filter(pagination)
    }

    save(item) {

        if(item.constructor.name === 'FormData') {
            return this.service.save(item)
        }
        if(this.isDeleteModal) {
            return this.delete(item.id)
        }
        let newItem = {...item};
        //newItem.idContratante = ContratanteStore.getters.contratante().idContratante;
        //newItem.idEmpresa = ContratanteStore.getters.empresa();
        //console.log(newItem);
        return this.service.save(newItem)
    }

    delete(id) {
        return this.service.delete(id)
    }

    filter(e) {
      return this.service.filter(e);
    }
}