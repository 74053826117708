<template>
    <div>

        <ValidationProvider ref="provider" :name="name" :rules="validationString" > <!--v-slot="{ errors }">-->
            <!--
            <component
                    v-bind:is="customValue?'v-switch':'v-switch'"
                :name="name"
                :id="name"
                :value="value"
                @change="onInput"
                :label="label"
                v-model=value
            />
            -->
                <v-switch v-if="customValue==true"
                :true-value="trueValue"
                :false-value="falseValue"
                :name="name"
                :id="name"
                :value="value"
                @change="onInput"
                :label="label"
                v-model=value
                :disabled="disabled"
            ></v-switch>
            <v-switch v-if="customValue==false"
                :name="name"
                :id="name"
                :value="value"
                @change="onInput"
                :label="label"
                v-model=value
                :disabled="disabled"
            ></v-switch>
            <!--
            <v-switch
                :true-value="trueValue"
                :false-value="falseValue"
                :name="name"
                :id="name"
                :value="value"
                @change="onInput"
                :label="label"
                v-model=value
            ></v-switch>
        -->
        </ValidationProvider>
    </div>

</template>

<script>
/* eslint-disable */
    export default {
        name: 'MdSwitch',
        data() {
            return {
            }
        },
        props: {
            /* eslint-disable */
            erros:{}, 
            value: {}, 
            /* eslint-enable */
            name: {
                type: String,
                required: true
            },
            customValue:{
                type: Boolean,
                default: true
            },
            disabled:{
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            trueValue: {
                default: 'S'
            },
            falseValue: {
                default: 'N'
            },
        },
        computed: {
            validationString() {
                let validation = this.validation || [];
                if (this.required) validation.push('required');
                return validation.join('|')
            }
        },
        methods: {
            onInput(event) {
                if ((!this.customValue) && (event==null)){
                    event = false;
                } else if ((this.customValue) && (event==null)){
                    event = this.falseValue.default;
                }
                console.log("onInput", event);
                if (this.disabled) {
                    return;
                }
                this.$emit('input', event);
            }
        },
        mounted() {
            this.$emit('input', this.value);

        }

    }

</script>

<style>

</style>
