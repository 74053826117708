import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import router from  './routes';
import components from './plugins/components';

import store from "./store";

Vue.config.productionTip = false

components.install(router)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
