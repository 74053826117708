<template>
  <div id="v-fields-usuarios">
    <v-layout row wrap>
      <v-flex xs12>
        <MdText
          :disabled="gridConfig.isDeleteModal"
          ref="nome"
          :validation="['required']"
          name="nome"
          label="Nome"
          v-model="gridConfig.editedItem.nome"
        ></MdText>
      </v-flex>
      <v-flex xs4>
        <MdText
          :disabled="gridConfig.isDeleteModal"
          ref="login"
          :validation="['required']"
          name="login"
          label="Email"
          v-model="gridConfig.editedItem.login"
        ></MdText>
      </v-flex>
      <v-flex xs4>
        <MdText
          :disabled="gridConfig.isDeleteModal"
          type="password"
          ref="senha"
          :validation="['required', 'min:3']"
          name="senha"
          label="Senha"
          v-model="gridConfig.editedItem.senha"
        />
      </v-flex>
      <v-flex xs4>
        <MdSwitch
          v-model="gridConfig.editedItem.bloqueado"
          trueValue="S"
          falseValue="N"
          label="Bloqueado"
          name="bloqueado"
          :disabled="gridConfig.isDeleteModal"
        ></MdSwitch>
      </v-flex>

    </v-layout>
  </div>
</template>

<script>
export default {
  name: "v-fields-usuarios",

  props: {
    gridConfig: {},

    exclusao: {
      type: Boolean,
    },
  },

  data() {
    return {};
  },

  created() {},

  methods: {
  },
};
</script>

<style>
</style>
