import Vue from 'vue'
import apiVariaveis from './apiVariaveis';
/*
import dotenv from 'dotenv'

dotenv.config()
const config = dotenv.config()
if(config.error){
  console.log('Could not load env file', config.error)
}
*/
import VueResource from 'vue-resource'
Vue.use(VueResource)

//console.log(apiVariaveis.URL_REMOTE);

export const IS_LOCAL = process.env.NODE_ENV !== 'production'
export const API_BASE_URL = IS_LOCAL ? apiVariaveis.URL_LOCAL+':'+apiVariaveis.APP_PORT+apiVariaveis.APP_ENDPOINT : apiVariaveis.URL_REMOTE+':'+apiVariaveis.APP_PORT+apiVariaveis.APP_ENDPOINT
export const API_NAME_TOKEN = apiVariaveis.LOCAL_TOKEN;
export const APP_TITLE = apiVariaveis.APP_TITLE;
export const APP_FOOTER = apiVariaveis.APP_FOOTER;
export const APP_EMPRESA_SEL = apiVariaveis.LOCAL_EMPRESA_SEL;
export const APP_RELEASE_DATE = apiVariaveis.APP_RELEASE_DATE;
export const APP_VERSION = apiVariaveis.APP_VERSION;