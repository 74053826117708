import './mdComponentes'
import './thirdComponents'
import './veeValidator/customValidator';
import './customMixin';

import mdLogin from './mdLogin';


export default {
    install(params) {
        mdLogin.install(params.router)
    }
}

