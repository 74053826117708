<template>
    <div>

        <ValidationProvider ref="provider" :name="name" :rules="validationString" v-slot="{ errors }">
            <component
                    v-bind:is="autocomplete?'v-autocomplete':'v-select'"
                    :items="computedItems"
                    outlined
                    :item-text="itemText"
                    :item-value="itemValue"
                    v-bind="$attrs"
                    ref="input"
                    :error-messages="errors[0]"
                    :label="label"
                    :name="name"
                    :id="name"
                    :value="value"
                    @input="onInput"
            >

            </component>

            <v-autocomplete v-if="false"></v-autocomplete>
            <v-select v-if="false"></v-select>
        </ValidationProvider>
    </div>

</template>

<script>
    export default {
        name: 'MdSelect',
        data() {
            return {
            }
        },
        props: {
            value: {}, // eslint-disable-line
            itemText: {
              type: String,
              required: false
            },
            itemValue: {
                type: String,
                required: false
            },
            autocomplete: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: null
            },
            validation: {
                type: Array,
                default: null
            },
            name: {
                type: String,
                required: true
            },
            required: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
        },
        computed: {
            validationString() {
                let validation = this.validation || [];
                if (this.required) validation.push('required');
                return validation.join('|')
            },
            computedItems() {
                /*
                let defaultValue = {};
                defaultValue[this.itemText] = 'Selecione...';
                defaultValue[this.itemValue] = null;
                return [defaultValue, ...this.items]
                */
               return this.items;
            }
        },
        methods: {
            onInput(event) {
                if (this.disabled)  {
                    return;
                }
                this.$emit('input', event);
            }
        },
        mounted() {
            this.$emit('input', this.value);

        }

    }

</script>

<style>

</style>